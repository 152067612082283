import { faFaceSmileWink } from "@fortawesome/free-regular-svg-icons";
import { faHiking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Layout from "../components/layout";

const GalleryPage = () => {
  return (
    <Layout pageTitle="Impressionen">
      <div className="container mx-auto px-4 mt-8">
        <h2>Impressionen</h2>
        <div className="mt-8 text-3xl px-8  md:px-40 text-justify">
          Die Bilder sind noch auf der <FontAwesomeIcon icon={faHiking}></FontAwesomeIcon> zum Bockmattli, wir bitten um ein wenig Geduld{" "}
          <FontAwesomeIcon icon={faFaceSmileWink}></FontAwesomeIcon>.
        </div>
      </div>
    </Layout>
  );
};

export default GalleryPage;
